import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
declare var $:any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private Router:Router) { }

  ngOnInit() {
  //   $(window).scroll(function(){
  //     var scroll = $(window).scrollTop();
  //     if(scroll < 5){
  //         $('.fixed-top').css('background', 'transparent');
  //     } else{
  //         $('.fixed-top').css('background', 'rgba(0,0,0,0.9)');
  //     }
  //     var width = $(window).width();
  //     if(width < 991){
  //       $('.fixed-top').css('background', 'rgba(0,0,0,0.9)');
  //   } 
  // });
  $(window).scroll(function(){
    $('nav').toggleClass('scrolled', $(this).scrollTop() > 5);
  });
  }

}
