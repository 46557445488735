import { Injectable } from '@angular/core';
import { Http, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';


@Injectable({
  providedIn: 'root'
})
export class Service {
  constructor(private http: Http) { }

  // localURL ='http://localhost:3000/mojoes/';
  localURL ='https://www.mojoestech.com:3000/mojoes/';
  
  public view(headers)
  {
    const url = this.localURL+'view';
    const response = this.http.get(url, headers).map(res => res.json());
    return response;
  }
 
  public contactus(body, headers)
  {
    const url = this.localURL+'regContact';
    const response = this.http.post(url, body, headers).map(res => res.json());
    return response;
  }

  public career(body, headers)
  {
    const url = this.localURL+'regcareer';
    const response = this.http.post(url, body, headers).map(res => res.json());
    return response;
  }

  public getquote(body, headers)
  {
    const url = this.localURL+'regQuote';
    const response = this.http.post(url, body, headers).map(res => res.json());
    return response;
  }
  
  public postFile(fileToUpload, headers): Observable<boolean> {
    const url = this.localURL+'upload';
    const response = this.http.post(url, fileToUpload, headers).map(res => res.json());
    return response;
  }

//   postFile(fileToUpload: File): Observable<boolean> {
//     const endpoint = 'your-destination-url';
//     const formData: FormData = new FormData();
//     formData.append('fileKey', fileToUpload, fileToUpload.name);
//     return this.httpClient
//       .post(endpoint, formData, { headers: yourHeadersConfig })
//       .map(() => { return true; })
//       .catch((e) => this.handleError(e));
// }
 

}
