import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Service } from '../service/service.service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
constructor(private formBuilder: FormBuilder,private Service:Service,private router:Router) { }

ngOnInit() {

  this.registerForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    notes: ['', [Validators.required]],
    acceptTerms: [false, Validators.requiredTrue]
  });
}
get f() { return this.registerForm.controls; }

        onSubmit(registerForm) {
          this.submitted = true;
          if (this.registerForm.invalid) {
            return;
          }
          let headers = new Headers({'Content-Type':'application/json'});
          var body = ({
            'firstname':registerForm['firstName'],
            'lastname':registerForm['lastName'],
            'emailID':registerForm['email'],
            'phoneNo':registerForm['phone'],
            'message':registerForm['notes']
           });
          this.Service.contactus(body, headers).subscribe(Response =>{
                  if(Response.status=='1')
                    {
                      alert("Thanks, For sharing your requirement !!! ");
                      // this.router.navigateByUrl('/');
                      location.reload();
                    }                    
                });
          }
          onReset() {
              this.submitted = false;
              this.registerForm.reset();
          }

}
