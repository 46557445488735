import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Service } from '../service/service.service'

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
constructor(private formBuilder: FormBuilder,private Service:Service,private router:Router) { }

ngOnInit() {
  this.registerForm = this.formBuilder.group({
    org: ['', Validators.required],
    budget: ['', Validators.required],
    wish: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    notes: ['', [Validators.required]],
    acceptTerms: [false, Validators.requiredTrue]
});
}
get f() { return this.registerForm.controls; }

    onSubmit(registerForm) {
      this.submitted = true;
      if (this.registerForm.invalid) {
        return;
      }
      let headers = new Headers({'Content-Type':'application/json'});
      var body = ({
        'fname':registerForm['firstName'],
        'lname':registerForm['lastName'],
        'email':registerForm['email'],
        'phone':registerForm['phone'],
        'org':registerForm['org'],
        'budget':registerForm['budget'],
        'wish':registerForm['wish'],
        'msg': registerForm['notes']
       });
      this.Service.getquote(body, headers).subscribe(Response =>{
              if(Response.status=='1')
                {
                  alert("Thanks, For sharing your requirement !!! ");
                  //this.router.navigateByUrl('/');
				  location.reload();
                }                    
            });
      }
      onReset(){
          this.submitted = false;
          this.registerForm.reset();
      }
}
