import { Component, OnInit } from '@angular/core';

declare var $;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      $('.owl-carousel').owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        nav: true,
    margin:10,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            nav:true
        },
        767:{
            items:1,
            nav:true,
        },
        991:{
            items:3,
            nav:true,
            
        }
    }
      });
    });
    


   
  }

}
