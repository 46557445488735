import { ResourceLoader } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Service } from '../service/service.service'
import { MustMatch } from './must-match.validator';
declare var $;
// declare var dataset:any;
@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
   // dataset:any;
    
  registerForm: FormGroup;
  submitted = false;
  fileData: File;
  FileError: string;
  FileName: string;
  constructor(private formBuilder: FormBuilder,private Service:Service,private router:Router) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      title: ['', Validators.required],
      msg: ['', [Validators.required]],
      // resume: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
  });

window.onload = () => {
    let sliderImagesBox = document.querySelectorAll('.cards-box');
    sliderImagesBox.forEach(el => {
      let imageNodes = el.querySelectorAll('.card:not(.hide)')
      let arrIndexes = []; // Index array
      (() => {
        // The loop that added values to the arrIndexes array for the first time
        let start = 0;
        while (imageNodes.length > start) {
          arrIndexes.push(start++);
        }
      })();
  
      let setIndex = (arr) => {
        for(let i = 0; i < imageNodes.length; i++) {
          imageNodes[i] = arr[i] // Set indexes
        }
      }
      el.addEventListener('click', () => {
        arrIndexes.unshift(arrIndexes.pop());
        setIndex(arrIndexes)
      })
      setIndex(arrIndexes) // The first indexes addition
    });
};
var slider = $('#slider');
var slideRight = function() {
  
  // switch state
  var dumb = slider.find('.behinder.left');
  slider.find('.behinder.right').removeClass('right').addClass('left');
  slider.find('.behind.right').removeClass('behind').addClass('behinder');
  slider.find('.center').removeClass('center').addClass('behind right');
  slider.find('.behind.left').removeClass('behind left').addClass('center');
  dumb.removeClass('behinder').addClass('behind');
}
var slideLeft = function() {
  
  // switch state
  var dumb = slider.find('.behinder.left');
  slider.find('.behinder.left').removeClass('left').addClass('right');
  slider.find('.behind.left').removeClass('behind').addClass('behinder');
  slider.find('.center').removeClass('center').addClass('behind left');
  slider.find('.behind.right').removeClass('behind right').addClass('center');
  dumb.removeClass('behinder').addClass('behind');
}
$('#right').on('click', function(){
  slideRight();
});
$('#left').on('click', function(){
  slideLeft();
});
$(document).ready(function(){
  // Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function(){
    $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
  });
  
  // Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
  }).on('hide.bs.collapse', function(){
    $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
  });
});
$(document).ready(function(){
  $('.owl-carousel').owlCarousel({
    autoplay: true,
    center: true,
    loop: true,
    nav: true,
margin:10,
responsiveClass:true,
responsive:{
    0:{
        items:1,
        nav:true
    },
    767:{
        items:1,
        nav:true,
    },
    991:{
        items:3,
        nav:true,
        
    }
}
  });
});

}

  get f() { return this.registerForm.controls; }

  fileProgress(event) {
    this.fileData = <File>event.target.files[0];
    console.log(this.fileData)
    this.FileName = this.fileData.name
    if(this.fileData.type!='application/pdf')
    {
      this.FileError='please upload PDF file.'
    }else{
      this.FileError=''
    }
    // const reader = new FileReader();
    // if (event.target.files && event.target.files.length) {
    //   const [file] = event.target.files;
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     // this.flag = "imagesrc";
    //     // this.imageSrc = reader.result as string;
    //   };
    // }
  }

  onSubmit(registerForm) {
    this.submitted = true;
    if(this.fileData==null || this.fileData==undefined ){
      this.FileError='please upload PDF file.'
    }else if(this.fileData!=null){
      if(this.fileData.type!='application/pdf')
      {
      this.FileError='please upload PDF file.'
      }
    }
    if (this.registerForm.invalid) {
      return;
    }

    let body = new FormData();
    let headers = new Headers({'Content-Type':'application/json'});
     body.append('fname',registerForm['firstName']),
     body.append('lname',registerForm['lastName']),
     body.append('email',registerForm['email']),
     body.append('phone',registerForm['phone']),
     body.append('job_title',registerForm['title']),
     body.append('resume',this.fileData),
     body.append('msg',registerForm['msg'])
    console.log(body)
    this.Service.career(body, headers).subscribe(Response =>{
            if(Response.status=='1')
              {
                alert("Thanks, We will contact you shortly !!! ");
                // this.router.navigateByUrl('careers');
                location.reload();
              }                    
          });
    }
    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }
}

