import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      $('.owl-carousel').owlCarousel({
        autoplay: true,
        autoplayTimeout:1500 ,
        autoplayHoverPause:true,
        center: true,
        loop: true,
        nav: true,
    margin:10,
    responsiveClass:true,
    responsive:{
        0:{
            items:3,
            nav:true
        },
        767:{
            items:3,
            nav:true,
        },
        1000:{
            items:5,
            nav:true,
            
        }
    }
      });
    });
 
  }

}
